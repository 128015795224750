import React from 'react'

import '../styles/experiencia_item.scss'

export default function ExperienciaItem({ item }) {
	return (
		<div className="card">
			<h3 className="card__number">{item.number}</h3>
			<div className="card__content">
				<h6 className="card__title">{item.title}</h6>
				<p className="card__text">{item.text}</p>
			</div>
		</div>
	)
}
