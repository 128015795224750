import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ExperienciaItem from '../../components/ExperienciaItem'

import '../../styles/experiencia.scss'

export default function Experiencia({ children, location }) {
	const items = [
		{
			number: '34.680',
			title: 'Unidades Inmobiliarias',
			text: 'Vivienda, lotes urbanizados, oficinas, comercio, hotelería, colegios, bodegas, usos mixtos.'
		},
		{
			number: "2.575.376",
			title: 'Metros Cuadrados',
			text: 'Construcción, estructuración, evaluación y gerencia de proyectos y gestión de tierra.'
		},
		{
			number: '76',
			title: 'Proyectos',
			text: 'Bogotá y Cundinamarca, Caldas, Costa Atlántica, Huila, Quindío, Risaralda, Valle.'
		},
		{
			number: '11',
			title: 'Años',
			text: 'Una trayectoria que nos consolida como una opción sólida para proyectos inmobiliarios.'
		}
	]

	return (
		<Layout currentPath={location}>
			<SEO title="Experiencia | Sobre Nosotros" />
			<div className="experiencia">
				<Link to="/#nosotros" className="back">
					&larr;Volver
				</Link>
				<div className="experiencia__title">
					<div className="experiencia__texture" />
					<h1>EXPERIENCIA</h1>
				</div>
				<div className="experiencia__content">
					<div className="experiencia__content__leftbar" />
					<p>
						Nuestro equipo cuenta con una experiencia en evaluación, estructuración, formulación y gerencia
						de proyectos inmobiliarios y de gestión de tierras que se podría resumir en los siguientes
						datos:
					</p>
					<div className="experiencia__content__rightbar" />
				</div>
				<div className="experiencia__bottom">
					{items.map((item, idx) => {
						return <ExperienciaItem item={item} key={idx} />
					})}
				</div>
			</div>
		</Layout>
	)
}
